import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { CurikoLoader } from '../curiko-loader/curiko-loader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #4c3b51;
`;

const Content = styled.div`
  color: white;
  padding: 40px 20px;
`;

type FullPageLoaderProps = {
  children?: React.ReactNode | React.ReactNode[] | string;
};

export const FullPageLoader = ({ children }: FullPageLoaderProps) => {
  return (
    <Container>
      <CurikoLoader />
      <Content>{children ?? <Typography variant="body1">Loading...</Typography>}</Content>
    </Container>
  );
};
