import styled from '@emotion/styled';

const Container = styled.div`
  color: #ffcf31;
  position: relative;
  width: 100px;
  height: 100px;
`;

const SvgIcon = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StarPath = styled.path`
  transform: scale(0.9);
  transform-origin: center;
  animation: curiko-star-pulse 2s ease-in-out infinite;
  animation-delay: 1.7s;
  @keyframes curiko-star-pulse {
    0% {
      transform: scale(0.8);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(0.8);
    }
    75% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const line1Length = 117;
const line1Gap = 107;
const LinePath1 = styled.path`
  stroke-dasharray: ${line1Length} ${line1Gap};
  stroke-dashoffset: 0;
  animation: curiko-line-dash1 4s ease-in-out infinite;
  @keyframes curiko-line-dash1 {
    0% {
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: ${line1Length + line1Gap};
    }
  }
`;

const line2Length = 104;
const line2Gap = 94;
const LinePath2 = styled.path`
  stroke-dasharray: ${line2Length} ${line2Gap};
  stroke-dashoffset: 0;
  animation: curiko-line-dash2 4s ease-in-out infinite;
  @keyframes curiko-line-dash2 {
    0% {
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dashoffset: ${line2Length + line2Gap};
    }
    100% {
      stroke-dashoffset: ${line2Length + line2Gap};
    }
  }
`;

export const CurikoLoader = () => {
  return (
    <Container>
      <SvgIcon
        width="46"
        height="47"
        viewBox="0 0 46 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <LinePath1
          d="M17.5 13.5C20 12 21.5 11 25 8C28.2657 5.20084 32 4.5 34.5 4.5C37 4.5 40.5 5 40.5 8.5C40.5 12 39.5 16 37.5 23C35.5 30 32.5 38.5 31 40.5C29.5 42.5 27 44 24.5 41C22 38 19 35 16 33C13 31 8 29.5 4.5 28.5C1 27.5 1.49999 24 2.5 22C3.50001 20 5.5 17 13 14.5"
          stroke="#ffcf31"
          strokeWidth="2"
        />
        <LinePath2
          d="M37.5 24C40.5 23 42.8427 21.8146 43.5 20.5C44.1573 19.1854 44.3868 17.5801 43 15.5C42 14 39.5 12.625 37 12C35 11.5 33 11.5 30 10.5C27 9.5 23 7 21 5C19 3 16.7864 1.73789 16 2.00001C15.2136 2.26212 14 3.5 13.5 8.5C13 13.5 13.5 14.5 13 18.5C12.5 22.5 11 26 9 29.5C7 33 6 37 8.5 40.5C11 44 14.5 45 16.5 45C18.5 45 21.5 45.5 25 42"
          stroke="#ffcf31"
          strokeWidth="2"
        />
        <StarPath
          d="M33.1076 26.2571C33.1215 26.2172 33.1215 26.1738 33.1075 26.1339C33.0936 26.0941 33.0666 26.0603 33.031 26.0382L30.7324 24.6058C29.6197 23.9124 28.7277 22.9095 28.1629 21.717C27.5981 20.5245 27.3843 19.1927 27.5472 17.8806L27.8891 15.126C27.8944 15.0841 27.8852 15.0416 27.8633 15.0057C27.8413 14.9697 27.8078 14.9425 27.7684 14.9285L27.5402 14.8472C27.5008 14.8331 27.4578 14.8331 27.4184 14.8472C27.379 14.8613 27.3456 14.8887 27.3237 14.9247L25.8855 17.2881C25.2003 18.4142 24.2092 19.3169 23.0307 19.8885C21.8522 20.4601 20.5361 20.6763 19.2394 20.5114L16.5253 20.1661C16.4839 20.1608 16.4419 20.17 16.4063 20.1922C16.3708 20.2145 16.3439 20.2484 16.3301 20.2883L16.2487 20.5223C16.2347 20.5621 16.2348 20.6056 16.2487 20.6454C16.2626 20.6853 16.2897 20.7191 16.3252 20.7412L18.6545 22.1931C19.7673 22.8865 20.6594 23.8894 21.2242 25.0819C21.789 26.2745 22.0027 27.6063 21.8398 28.9185L21.5042 31.6211C21.4989 31.6631 21.5081 31.7055 21.53 31.7415C21.552 31.7775 21.5855 31.8047 21.6249 31.8187L21.8735 31.907C21.9128 31.9211 21.9558 31.9211 21.9952 31.907C22.0346 31.8929 22.068 31.8655 22.0899 31.8295L23.5014 29.5098C24.1867 28.3838 25.1778 27.4811 26.3563 26.9095C27.5348 26.3379 28.8509 26.1217 30.1476 26.2866L32.826 26.6271C32.8674 26.6325 32.9094 26.6233 32.945 26.601C32.9805 26.5788 33.0074 26.545 33.0212 26.5051L33.1076 26.2571Z"
          fill="currentColor"
        />
      </SvgIcon>
    </Container>
  );
};
