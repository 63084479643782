'use client';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useAuth0 } from '@/auth';
import { FullPageLoader } from '@/components/full-page-loader/full-page-loader';

export const LoginPage = () => {
  const router = useRouter();
  const { loginWithRedirect, isLoading, isAuthenticated, redirecting, error } = useAuth0();

  useEffect(() => {
    // wait until auth0 lib has loaded
    if (isLoading || error) {
      return;
    }

    // trigger login if not authenticated
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: '/',
        },
      });
      return;
    }

    // redirect to home page if authenticated AND auth0 provider is not already redirecting
    if (!redirecting) {
      router.replace('/');
    }
  }, [router, loginWithRedirect, isLoading, isAuthenticated, redirecting, error]);

  return <FullPageLoader />;
};

export default LoginPage;
